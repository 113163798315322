/*=========================================================================================
  File Name: moduleCompetitionMutations.js
  Description: Competition Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  SET_Home(state, statistics) {
    debugger;
    try {
     
      state.statistics = statistics;
      
    } catch (error) {
      console.error("Failed to parse statistics data JSON:", error);
      state.statistics = [];
    }
  },
}
