<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/6 xl:w-1/6 mb-base" v-for="(stat, index) in statisticsCards" :key="index">
        <statistics-card-line
          :icon="stat.icon"
          :statistic="GetAllstatistics[stat.countKey] || 0"
          :statisticTitle="stat.title"
          :chartData="[]"
          :color="stat.color"
          :hideChart="true"
          style="font-size: 11px;font-weight: bolder;"
          type="area"
        />
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vx-card :title="$t('RecommendedAds')" class="mb-base p-4" code-toggler>
          <div class="mt-5">
            <chartjs-component-bar-chart ref="chart1" :height="250" :data="data" :options="options"></chartjs-component-bar-chart>
          </div>
          <template slot="codeContainer"></template>
        </vx-card>
      </div>

      <div class="vx-col w-full md:w-1/2">
        <vx-card :title="$t('FeaturedAds')" class="mb-base p-4" code-toggler>
          <div class="mt-5">
            <chartjs-component-bar-chart ref="chart2" :height="250" :data="data2" :options="options2"></chartjs-component-bar-chart>
          </div>
          <template slot="codeContainer"></template>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <vx-card :title="$t('FeaturedAndRecomended')" class="mb-base p-4" code-toggler>
          <div class="mt-5">
            <chartjs-component-bar-chart ref="chart3" :height="250" :data="data3" :options="options3"></chartjs-component-bar-chart>
          </div>
          <template slot="codeContainer"></template>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-1/2">
        <vx-card :title="$t('currentUsers')" class="mb-base p-4" code-toggler>
          <div class="mt-5">
            <chartjs-component-bar-chart ref="chart4" :height="250" :data="data4" :options="options4"></chartjs-component-bar-chart>
          </div>
          <template slot="codeContainer"></template>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";
import moduleHome from "@/store/Home/moduleHome.js";
// import { useI18n } from 'vue-i18n';

// const { $t } = useI18n();
export default {
  components: {
    StatisticsCardLine,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      users:0,
      FeaturedAndRecomendedAds:0,
      RecommendedAds:0,
      FeaturedAds:0,
      ServiceRequestsThisWeek:0,
      statisticsCards: [
        { icon: "UserPlusIcon", countKey: "CustomersCount", title: this.$t("Users"),style: "color: primary;" },
        { icon: "ThumbsUpIcon", countKey: "RecommendedAdsCount", title :this.$t("RecommendedAds"), color: "success" },
        { icon: "StarIcon", countKey: "FeaturedAdsCount", title:  this.$t("FeaturedAds"), color: "warning" },
        { icon: "AwardIcon", countKey: "FeaturedAndRecomendedCount", title: this.$t("FeaturedAndRecomended"), style: "color: yellow;"  },
        { icon: "EyeIcon", countKey: "underReviewAdsCount", title: this.$t("underReviewAds"), color: "warning"},
        { icon: "XCircleIcon", countKey: "refusedAdsCount", title: this.$t("refusedAds"),color: "danger" },
      ],
      data: {
        labels: ["اعلانات مميزة ومروجة"],
        datasets: [{
          label: " اعلانات مميزة ومروجة  تمت خلال الاسبوع",
          backgroundColor: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E"],
          data: [],
          fontFamily: "Almarai"
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 500,
              stepSize: 100,
              fontFamily: "Almarai"
            }
          }],
          xAxes: [{
            ticks: {
              fontFamily: "Almarai"
            }
          }]
        },
        legend: {
          labels: {
            fontFamily: 'Almarai, sans-serif'
          }
        }
      },
      data2: {
        labels: ["الاعلانات المميزة"],
        datasets: [{
          label: "اعلانات مميزة معروضه خلال الاسبوع",
          backgroundColor: ["#7367F0", "#28C76F", "#EA5455", "#FF9F43", "#1E1E1E", "#6495ED", "#DE3163", "#DFFF00"],
          data: [],
          fontFamily: "Almarai"
        }]
      },
      options2: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 500,
              stepSize: 100,
              fontFamily: "Almarai"
            }
          }],
          xAxes: [{
            ticks: {
              fontFamily: "Almarai"
            }
          }]
        },
        legend: {
          labels: {
            fontFamily: 'Almarai, sans-serif'
          }
        }
      },
      data3: {
        labels: ["الاعلانات المروجة"],
        datasets: [{
          label: "اعلانات مروجة معروضة خلال الاسبوع",
          backgroundColor: ["#7367F0"],
          data: [],
          fontFamily: "Almarai"
        }]
      },
      options3: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 500,
              stepSize: 100,
              fontFamily: "Almarai"
            }
          }],
        },
        legend: {
          labels: {
            fontFamily: 'Almarai, sans-serif'
          }
        }
      },
      data4: {
        labels: ["المستخدمين"],
        datasets: [{
          label: "المستخدمين الحاليين علي الموقع ",
          backgroundColor: ["#7367F0"],
          data: [],
          fontFamily: "Almarai"
        }]
      },
      options4: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 500,
              stepSize: 100,
              fontFamily: "Almarai"
            }
          }],
          xAxes: [{
            ticks: {
              fontFamily: "Almarai"
            }
          }]
        },
        legend: {
          labels: {
            fontFamily: 'Almarai, sans-serif'
          }
        }
      }
    };
  },
  computed: {
    GetAllstatistics() {
      return this.$store.state.moduleHome.statistics || {};
    }
  },
  created() {
    if (!moduleHome.isRegistered) {
      this.$store.registerModule("moduleHome", moduleHome);
      moduleHome.isRegistered = true;
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$store.dispatch("moduleHome/fetchDataListItems")
        .then((res) => {
          console.log('Fetched data:', res.data.data);
          this.users=res.data.data.CustomersCount;
          this.FeaturedAndRecomendedAds=res.data.data.FeaturedAndRecomendedAdsCount;
          this.RecommendedAds=res.data.data.RecommendedAdsCount;
          this.FeaturedAds=res.data.data.FeaturedAdsCount;
         
          this.data4.datasets[0].data = [this.users];
          this.data2.datasets[0].data = [ this.FeaturedAds];
          this.data.datasets[0].data = [ this.FeaturedAndRecomendedAds];
          this.data3.datasets[0].data = [ this.RecommendedAds];
          this.$nextTick(() => {
            // Force chart re-render
            this.$refs.chart4.renderChart(this.data4, this.options4);
            this.$refs.chart2.renderChart(this.data2, this.options2);
            this.$refs.chart1.renderChart(this.data, this.options);
            this.$refs.chart1.renderChart(this.data3, this.options);

          });
        })
        .catch(err => {
          console.error('Error fetching data:', err);
        });
    },
    updateChartData(chartData, data, labelKey, dataKey) {
      debugger;
      chartData.labels = [];
      chartData.datasets[0].data = [];

      for (let i = 0; i < data.length; i++) {
        chartData.labels.push(data[i][labelKey]);
        chartData.datasets[0].data.push(data[i][dataKey]);
      }

    },
    // reRenderCharts() {
    //   this.$refs.chart1.renderChart();
    //   this.$refs.chart2.renderChart();
    //   this.$refs.chart3.renderChart();
    //   this.$refs.chart4.renderChart();
    // }
  },
  // watch: {
  //   data: {
  //     handler() {
  //       this.reRenderCharts();
  //     },
  //     deep: true
  //   },
  //   data2: {
  //     handler() {
  //       this.reRenderCharts();
  //     },
  //     deep: true
  //   },
  //   data3: {
  //     handler() {
  //       this.reRenderCharts();
  //     },
  //     deep: true
  //   },
  //   data4: {
  //     handler() {
  //       this.reRenderCharts();
  //     },
  //     deep: true
  //   }
  // }
};
</script>

<style lang="scss">
.statistics-card-line .card-title {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}
.statisticTitle {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chartjs-chart {
  font-family: 'Elmarrai';
}
.chartjs-chart .chartjs-text {
  font-family: 'Elmarrai';
}
.chartjs-chart .chartjs-text {
  font-family: 'Elmarrai', sans-serif !important;
}
</style>
